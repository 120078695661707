<template>
  <div class="item-block">
    <div class="title">
      <div class="t-l">
        <div class="t-l-t">{{data.year + ' ' + data.batch + ' ' + (data.type === 'urgent' ? '紧急' : '')}}</div>
        <div class="t-l-s" :class="{'t-l-end': data.status === 'end'}">{{data.statusText}}</div>
      </div>
      <div class="t-r">
        <div class="budget-btn" :class="{'g-btn': item.key === 'details', 'o-btn': item.key === 'edit'}" @click="$emit('action', { action: item, data })" v-for="(item, index) in btns" :key="index">
          {{item.label}}
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content-item">
        <div class="title-2">参与科室</div>
        <div class="num">
          <div class="n">{{cyOrgNum}}</div>
          <div class="dw">个</div>
        </div>
      </div>
      <div class="content-item">
        <div class="title-2">参与领导</div>
        <div class="num">
          <div class="n">{{cyLeaderNum}}</div>
          <div class="dw">个</div>
        </div>
      </div>
      <div class="content-item">
        <div class="title-2">已报物品数</div>
        <div class="num">
          <div class="n">{{data.countNum}}</div>
          <div class="dw">件</div>
        </div>
      </div>
      <div class="content-item content-item-r">
        <div class="title-2">采购金额</div>
        <div class="num">
          <div class="n">{{data.countTotal}}</div>
          <div class="dw">元</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: { type: Object, default: () => ({}) }
  },
  computed: {
    // unable () {
    //   const ableOrgIds = [
    //     ...(this.data.applyOrgIds || '').split(','),
    //     ...(this.data.preOrgIds || '').split(','),
    //     ...(this.data.finalOrgId ? [this.data.finalOrgId] : [])
    //   ].map(v => Number(v))
    //   return this.$store.getters.currentOrgId && ableOrgIds.includes(this.$store.getters.currentOrgId)
    // },
    cyOrgNum () {
      let cyOrgs = []
      let submitOrgIds = this.data.submitOrgIds ? this.data.submitOrgIds.split(',') : []
      submitOrgIds.forEach(v => !cyOrgs.includes(v) ? cyOrgs.push(v) : null)
      let busOrgIds = this.data.busOrgIds ? this.data.busOrgIds.split(',') : []
      busOrgIds.forEach(v => !cyOrgs.includes(v) ? cyOrgs.push(v) : null)
      return cyOrgs.length
    },
    cyLeaderNum () {
      let cyLeads = []
      let deanUserIds = this.data.deanUserIds ? this.data.deanUserIds.split(',') : []
      deanUserIds.forEach(v => !cyLeads.includes(v) ? cyLeads.push(v) : null)
      let leadUserIds = this.data.leadUserIds ? this.data.leadUserIds.split(',') : []
      leadUserIds.forEach(v => !cyLeads.includes(v) ? cyLeads.push(v) : null)
      let purchaseMethodLeadUserIds = this.data.purchaseMethodLeadUserIds ? this.data.purchaseMethodLeadUserIds.split(',') : []
      purchaseMethodLeadUserIds.forEach(v => !cyLeads.includes(v) ? cyLeads.push(v) : null)
      return cyLeads.length
    },
    btns () {
      if (this.data && this.data.actions) {
        const actions = this.data.actions.filter(v => !['add_detail'].includes(v.key))
        if (this.data.status !== 'creating') {
          return [
            { label: '查看', key: 'details' },
            { label: '会议材料', key: 'file' },
            ...actions
          ]
        } else {
          return actions
        }
      } else {
        return []
      }
    },
    actions () {
      let actions = {}
      ;(this.data.actions || []).forEach(item => actions[item.key] = true)
      return actions
    }
  }
}
</script>

<style lang="less" scoped>
.item-block {
  width: 100%;
  height: 198px;
  background: #FFFFFF;
  border-radius: 6px;
  .title {
    height: 53px;
    padding: 0 11px 0 25px;
    border-bottom: 1px solid #E7E7E7;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .t-l {
    display: flex;
    font-size: 18px;
    font-family: Helvetica;
    color: #000000;
    .t-l-s {
      color: #3776ED;
      margin-left: 16px;
    }
    .t-l-end {
      color: #FF9D6B !important;
    }
  }
  .t-r {
    display: flex;
    justify-content: flex-end;
  }
  .content {
    height: 144px;
    padding: 28px 25px 29px;
    display: flex;
    .content-item {
      flex: 1;
      margin-right: 45px;
      padding: 13px 0;
      height: 100%;
      background: #F6F8F9;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      .title-2 {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #222222;
        line-height: 1;
      }
      .num {
        display: flex;
        align-items: flex-end;
        line-height: 1;
        .n {
          font-size: 30px;
          font-family: DIN-Bold, DIN;
          font-weight: bold;
          color: #222222;
        }
        .dw {
          font-size: 14px;
          font-family: Helvetica;
          color: #222222;
          padding-bottom: 3px;
          padding-left: 3px;
        }
      }
    }
    .content-item-r {
      margin-right: 0;
    }
  }
  .budget-btn {
    background: #3776ED;
    border-radius: 4px;
    height: 26px;
    margin-left: 10px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    padding: 0 10px;
    display: flex;
    align-items: center;
  }
  .g-btn {
    background: #1ADBA5;
  }
  .o-btn {
    background: #FF7D00;
  }
}
</style>